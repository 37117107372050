import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Heading4 from '../../components/Type/Heading4'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({ location }) =>
(
  <Layout>

    <SEO title="BMW X3 xDrive30d buyers guide"
      description="Buying a BMW X3? Read more for X3 prices and our advice on buying the best example"
      location={location}
    />

    <PageHeader
      title="BMW X3 xDrive30d buyers guide"
      text="Cars with kudos typically hold their value well, and that is certainly true of the BMW X3 xDrive30d. Read our market analysis and price guides for buying a used X3."
    />

    <SectionPageMargin>

      <Paragraph1>
        Last update: 9 December 2021
      </Paragraph1>


      <Heading2>Buying a BMW X3? Read more for X3 prices and our advice on buying the best example</Heading2>

      <Heading4>Market Analysis</Heading4>

      <Paragraph1>
      Compact SUVs from prestige manufacturers are always in demand, offering the practicality of a family car with the security you get from a high driving position, and shot through with style and luxury. 
      </Paragraph1>

      <Paragraph1>
      The third generation BMW X3, sometimes seen with the suffix G01 to distinguish it from earlier models, is the epitome of what makes this class of car so popular.
      </Paragraph1>

      <Paragraph1>
      Here we’re looking specifically at the BMW X3 xDrive30d (G01). The xDrive portion of its badge denotes that it’s four-wheel drive – there are rear-drive versions on some markets – while 30d refers to its 3.0-litre turbodiesel engine. With some car ranges the diesels are less desirable than the petrol engines, but BMWs diesels are smooth, extremely punchy, and very economical. As a consequence, the X3 xDrive30d is the pick of the range.
      </Paragraph1>

      <Paragraph1>
      Cars with kudos typically hold their value well, and that is certainly true of the BMW X3 xDrive30d. Helping bolster its residuals still further is the continued shortage of brand new cars, a situation caused by Covid-induced shipping problems together with a global shortage of the microchips without which modern cars won’t run. The lack of new cars puts good quality secondhand models in high demand.
      </Paragraph1>

      <Paragraph1>
      According to Summon’s pricing data, based on private and dealer sales advertisements from the past 12 months, values of used BMW X3 xDrive30ds are strong. The average price for a 2017 example is $65,661 and if you look at one that’s two years younger, that average price rises to just the other side of $80K. What the averages disguise is the fact that once an X3 xDrive30d has about 40,000km on the clock the price evens out, whether it’s a 2017, 2018 or 2019 example – you’ll pay almost $66K for a 2017 car with 40K+ km, and about $68,500 for a 2019 model.
      </Paragraph1>

      <Paragraph1>
      In terms of mileage within a given year making a difference to the price, don’t be expecting miracles as the digits on the odometer roll round. A BMW X3 xDrive30d of 2017 vintage does show steady depreciation according to mileage, but the difference between a car with 10-20K on the clock and one with 40-50K is about $6700. For a 2018 car the pattern of depreciation is odd. With less than 20K on the clock you’re looking at around $74,700, but between 20 and 60K prices hold steady at nearly the $70,000 mark. When the mileage passes 60K, however, the price tumbles to around $52,600, although this could be an anomaly caused by a small sample size at this mileage and a desperate owner.
      </Paragraph1>

      <Paragraph1>
      What’s on the odometer does affect the price of a 2019 BMW X3 xDrive30d, but not in the way you might think. Strangely, while a car with less than 10K on the clock popped up in the data at $76,339, the average for cars with 30K+ is $81,766. If you can find an xDrive30d with 50K+ under its wheels you might pay as low as $67,126, but Summon’s data reveals that the average mileage for this year is just 23,428km.
      </Paragraph1>


      <Heading4>What is it?</Heading4>

      <Paragraph1>
      The BMW X3 xDrive30d (G01) is an attractive, well-proportioned, mid-sized SUV with on-demand four-wheel drive and an outstanding eight-speed sports automatic gearbox that BMW calls Steptronic. It’s powered by an excellent 3.0-litre straight-six turbodiesel engine that produces 195kW and, more importantly, 620Nm of torque – the latter is what gives the xDrive30d such sparkling mid-range performance, the sort that counts most in real-world driving. In keeping with BMW’s brand ethos, it’s a pleasure to drive whether on back roads or back streets.
      </Paragraph1>

      <Paragraph1>
      Launched in 2017, the xDrive30d (G01) is the third generation BMW X3 and for the Australian market is extremely well-equipped. Amongst its convenience and luxury features are 20-inch alloy wheels, leather upholstery, tri-zone climate control, adaptive cruise control, colour screen infotainment system with built-in hard-drive, voice-controlled satnav and DAB radio, full digital instrumentation, powered tailgate, automatic headlights and wipers, parking sensors and cameras front and rear, parking assistance with automated steering, and keyless central locking and starting.
      </Paragraph1>

      <Paragraph1>
      Safety systems include low-speed autonomous emergency braking with pedestrian detection, lane departure warning with active lane-keeping assist, head-up display, and rear cross-traffic warning and autonomous braking when reversing with limited visibility.

      </Paragraph1>

      <Paragraph1>
      As is BMW’s way, there was a plethora of optional equipment for the original purchaser to choose from, so study carefully what the car you’re looking at is kitted out with. On the list of desirable – though not essential – features to be aware of is the M Sport Package that blesses the X3 xDrive30d with sportier looks outside and better seats within; the Harman Kardon 16-speaker surround sound system; electric glass panoramic sunroof; ventilated front seats; and Apple CarPlay, which really should have been standard fare.
      </Paragraph1>



      <Heading4>What to look out for</Heading4>
      <Paragraph1>
        <strong>Engine:</strong>  It’s still early days for the (G01) X3 xDrive30d’s 3.0-litre turbodiesel and to date there are no reported problems. However, as with all diesels be wary of cars that have spent their whole lives driving around town – the diesel particulate filter (DPF) in the exhaust may be getting clogged, so cleaning could be in order.
      </Paragraph1>

      <Paragraph1>
        <strong>Rear driveshafts:</strong>  This is not an especially common problem, but some owners report squeaking from the rear driveshaft seals, which have had to be replaced.
      </Paragraph1>

      <Paragraph1>
        <strong>Interior rattles:</strong> Not a universal issue, but there are some reports of rattly sunroofs and front seat bases.
      </Paragraph1>


      <Paragraph1>
        <strong>Electrics:</strong> Dimming dashboard lights, including satnav. Emergency call system malfunction – seems to correct itself in some instances.
      </Paragraph1>

      <Paragraph1>
        <strong>Recalls:</strong> Check if the car you’re looking at was subject to the following recalls and that the rectification work was carried out – airbags, brake calipers, rear camera and the lane departure/steering assist system.
      </Paragraph1>

    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage
